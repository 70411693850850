import { Container, ToastContainer, Toast, Tabs, Tab, Row, Col, Button, Table } from "react-bootstrap";
import { useState, useEffect, useContext, useCallback } from 'react'
import { useCookies } from 'react-cookie';
import GameContext from "./GameContext";
import request from "./Api";
import { v4 as uuidv4 } from 'uuid';
import { createHashHistory } from 'history';
import Header from "./Header"
import { BsPersonPlusFill, BsPersonDashFill, BsSkipStartCircle } from "react-icons/bs";
function Player() {
  const game = useContext(GameContext);
  const [show, setShow] = useState(false);
  const [cookies, setCookie] = useCookies(['state',"isAdmin"]);
  const [key, setKey] = useState(null);
  const [airports, setAirports] = useState([]);
  const [teamId, setTeamId] = useState(null);
  const history = createHashHistory();

  function JoinTeam(team, airport) {
    var command = {
      "clientId": cookies.state.clientId,
      "teamId": team.id,
      "airportId": airport.id,
      "memberName": cookies.state.clientId
    };
    request.post("api/airports/join", command).then(response => {
      setTeamId(team.id);
      setCookie('state', { clientId: cookies.state.clientId, currentGameIdentify: cookies.state.currentGameIdentify, teamId: team.id, teamName: team.name, airportId: airport.id, airportName: airport.name }, { path: '/' });
      LoadAirports();
    })
  }
  function LeaveTeam(team) {
    var command = {
      "clientId": cookies.state.clientId,
      "teamId": team.id
    };
    request.delete("api/airports/leave", { data: command }).then(response => {
      setTeamId(false);
      setCookie('state', { clientId: cookies.state.clientId, currentGameIdentify: cookies.state.currentGameIdentify, teamId: undefined, teamName: undefined, airportId: undefined, airportName: undefined }, { path: '/' });
      LoadAirports();
    })
  }
  function GetTeamId() {
    return teamId === null ? cookies.state.teamId : teamId;
  }

  //function LoadAirports() {
  const LoadAirports = useCallback(() => {
    request.get("api/airports").then(response => {
      setAirports(response.data);
      if (!cookies.state || cookies.state.currentGameIdentify !== game.currentGameIdentify) {
        setCookie('state', { clientId: uuidv4(), currentGameIdentify: game.currentGameIdentify }, { path: '/' });
      }
      if (key == null) {
        var myAirport = !cookies.state ? null : response.data.find(a => a.teams.some(b => b.id === cookies.state.teamId));
        if (myAirport) {
          setKey(myAirport.name);
        } else {
          setKey(response.data[0].name);
        }
      }
    })
  }, [ cookies, game, key, setCookie ])
  //}

  const StartGame = () => {
    // request.get("api/is-game-start").then(response => {
    //   if (response.data) {
    //     history.push('/game')
    //   } else {
    //     setShow(true);
    //   }

    // });
    history.push('/game')
  }
  useEffect(() => {
    if (game) {
      LoadAirports();
    }
  }, [game, LoadAirports])
  return (
    <Container>
      <ToastContainer className="p-3" position={"top-end"}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Controller Chaos</strong>
          </Toast.Header>
          <Toast.Body>Please wait for the game start!</Toast.Body>
        </Toast>
      </ToastContainer>
      <Header />
      <Row className="mt-3">
        <Col className="border-bottom border-start border-end border-1 p-0 m-0">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            {cookies.state && airports.sort((a, b) => a.index > b.index ? 1 : -1).map(airport =>
              <Tab key={airport.id} eventKey={airport.name} title={airport.name} disabled={!airport.isEnable}>
                <Container>
                  <Table striped bordered hover>
                    <thead>
                      <tr >
                        <th>Team Name</th>
                        <th>Member Count</th>
                        {/* <th>Status</th> */}
                        <th width="220px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {airport.teams.sort((a, b) => a.index > b.index ? 1 : -1).map(team =>
                        <tr key={team.id}>
                          <td>{team.name}</td>
                          <td>{team.members.length}</td>
                          {/* <td>{team.isEnable ? "Active" : "Deactive"}</td> */}
                          <td>
                            {
                              game.status !== "Deactive" && airport.isEnable && 
                              <>
                                {GetTeamId() && GetTeamId() === team.id &&
                                  <>
                                    <Button variant="danger" onClick={() => LeaveTeam(team)} size="sm">
                                      <BsPersonDashFill />
                                      Leave</Button>
                                    <Button variant="primary" className="mx-2" onClick={StartGame} size="sm">
                                      <BsSkipStartCircle />
                                      Start
                                    </Button>
                                  </>
                                }
                                {!GetTeamId() && <Button variant="primary" onClick={() => JoinTeam(team, airport)} size="sm">
                                  <BsPersonPlusFill />
                                  Join
                                </Button>}
                              </>
                            }
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Container>
              </Tab>

            )}


          </Tabs>
        </Col>
      </Row>
    </Container>


  );
}

export default Player;
import { Row, Col, Container, Form, Table, Tabs, Tab, Button } from "react-bootstrap";
import { useEffect, useState } from 'react';
import request from './Api';

function LogViewer({ airportStatistic}) {

    const [logShifts, setLogShifts] = useState([]);
    const [logTeams, setLogTeams] = useState([]);
    const [logEntries, setLogEntries] = useState([]);
    const [selectedLogAirport, setSelectedLogAirport] = useState('');
    const [selectedLogShift, setSelectedLogShift] = useState('');
    const [selectedLogTeam, setSelectedLogTeam] = useState('');

    function updateLogEntries(airportName, shiftNum, teamName) {
        if (shiftNum && teamName) {
            request.get("api/log/" +airportName +"/entries-shift-team/" +shiftNum +"/" +teamName).then(resp => {
                setLogEntries(resp.data);
            });
        } else if (shiftNum) {
            request.get("api/log/" +airportName +"/entries-shift/" +shiftNum).then(resp => {
                setLogEntries(resp.data);
            });
        } else if (teamName) {
            request.get("api/log/" +airportName +"/entries-team/" +teamName).then(resp => {
                setLogEntries(resp.data);
            });
        } else {
            request.get("api/log/" +airportName +"/entries").then(resp => {
                setLogEntries(resp.data);
            });
        }
    }
    function logAirportChange(e) {
        if (!e.target.value) {
            setSelectedLogAirport('');
            setLogShifts([ ]);
            setSelectedLogShift('');
            setLogTeams([ ]);
            setSelectedLogTeam('');
            setLogEntries([ ]);
            return;
        }
        setSelectedLogAirport(e.target.value);
        request.get("api/log/" +e.target.value +"/shifts").then(resp => {
            setLogShifts(resp.data);
        });
        setSelectedLogShift('');
        request.get("api/log/" +e.target.value +"/teams").then(resp => {
            setLogTeams(resp.data);
        });
        setSelectedLogTeam('');
        updateLogEntries(e.target.value, null, null);
    }
    function logShiftChange(e) {
        var shiftNum = e.target.value;
        if (shiftNum == 'All shifts')
            shiftNum = '';
        setSelectedLogShift(shiftNum);
        updateLogEntries(selectedLogAirport, shiftNum, selectedLogTeam);
    }
    function logTeamChange(e) {
        var teamName = e.target.value;
        if (teamName == 'All teams')
            teamName = '';
        setSelectedLogTeam(teamName);
        updateLogEntries(selectedLogAirport, selectedLogShift, teamName);
    }

    return (
    <Container fluid className="mt-3">
        <Row>
            <Col md={4}>
                <Form.Select aria-label="Select airport" onChange={logAirportChange} value={selectedLogAirport}>
                    <option>Select airport</option>
                    {airportStatistic.map(airport =>
                    <option value={airport.airportName} key={airport.airportId}>{airport.airportName}</option>
                    )}
                </Form.Select>
            </Col>
            <Col md={4}>
                <Form.Select aria-label="Select shift" onChange={logShiftChange} value={selectedLogShift}>
                    <option>All shifts</option>
                    {logShifts.map(shift =>
                    <option value={shift.shiftNum} key={shift.shiftNum}>{shift.shiftNum}</option>
                    )}
                </Form.Select>
            </Col>
            
            <Col md={4}>
                <Form.Select aria-label="Select team" onChange={logTeamChange} value={selectedLogTeam}>
                    <option>All teams</option>
                    {logTeams.map(team =>
                    <option value={team.teamName} key={team.teamName}>{team.teamName}</option>
                    )}
                </Form.Select>
            </Col>
        </Row>
        { logEntries.length > 0 &&
        <Row>
            <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>Event</th>
                        <th>Team</th>
                        <th>Shift</th>
                        <th>Raw Team Revenue</th>
                        <th>Team Bonus</th>
                        <th>Team Penalty</th>
                        <th>Team Revenue</th>
                        <th>Airport Revenue</th>
                    </tr>
                </thead>
                <tbody>
                    {logEntries.map(entry =>
                    <tr key={entry.id}>
                        <td>{new Date(entry.ts).toLocaleString()}</td>
                        <td>{entry.message}</td>
                        <td>{entry.teamName}</td>
                        <td>{entry.shiftNum}</td>
                        <td>{entry.teamName ? entry.rawTeamRevenue : ''}</td>
                        <td>{entry.teamName ? entry.teamBonus : ''}</td>
                        <td>{entry.teamName ? entry.teamPenalty : ''}</td>
                        <td>{entry.teamName ? entry.teamRevenue : ''}</td>
                        <td>{entry.airportRevenue}</td>
                    </tr>
                    )}  
                </tbody>
            </Table>
        </Row>
        }
    </Container>
    );

}

export default LogViewer;
